import PostIdImage1 from './blogImage/postIdImage1.jpg'
import PostIdImage2 from './blogImage/postIdImage2.jpg'
import PostIdImage3 from './blogImage/postIdImage3.jpg'

const posts = [
    {
        id: 1,
        link: 'Obamacare-para-estudantes-com-visto-F1',
        isActive: true,
        image: PostIdImage1,
        date: '09/01/2025',
        title: 'Obamacare para Estudantes com Visto F1: Como Funciona o Seguro de Saúde nos EUA',
        category: 'Health Insurance',
        text: "Estudar nos Estados Unidos pode ser uma experiência incrível, mas um dos maiores desafios para estudantes internacionais, especialmente para os brasileiros com visto F1, é o alto custo dos cuidados médicos. O Affordable Care Act (ACA), também conhecido como Obamacare, pode ser uma opção importante para garantir que você tenha acesso a um seguro de saúde adequado. Entender a eligibilidade para o ACA e explorar alternativas de seguro de saúde nos EUA são passos cruciais para garantir que sua saúde esteja protegida enquanto estuda nos Estados Unidos. \n\n" +
            "*[O que é o Obamacare?]* \n\n" +
            "O Obamacare, oficialmente chamado de Affordable Care Act (ACA), é uma legislação dos Estados Unidos que visa tornar os planos de saúde mais acessíveis para todos, independentemente de condições de saúde preexistentes. Através do ACA, estudantes internacionais, incluindo os brasileiros com visto F1, podem acessar planos de saúde através de marketplaces estaduais ou do site federal HealthCare.gov. Esses planos cobrem consultas médicas, emergências, internações e medicamentos, proporcionando segurança para quem vive nos EUA. \n\n" +
            "Além disso, o ACA oferece Premium Tax Credit para reduzir o custo dos planos de saúde, ajustando o valor das mensalidades com base na sua renda. No entanto, a eligibilidade para esses créditos pode variar, especialmente para estudantes internacionais, dependendo do status fiscal do estudante. \n\n" +
            "*[Estudantes com Visto F1 São Elegíveis para o Obamacare?]* \n\n" +
            "A elegibilidade para o Obamacare depende diretamente do status fiscal do estudante nos EUA. Ou seja, a classificação fiscal de um estudante com visto F1 determinará se ele pode ou não se inscrever nos planos oferecidos pelo ACA. Aqui estão as duas principais situações: \n\n" +
            "1. Não Residentes Fiscais (Primeiros Cinco Anos com Visto F1). \n" +
            "Durante os primeiros cinco anos de estadia nos Estados Unidos, a maioria dos estudantes com visto F1 é classificada como não residente fiscal. Isso significa que, em geral, eles não são obrigados a se inscrever no Obamacare e não são elegíveis para os planos de saúde oferecidos no marketplace federal. A razão para isso é que os estudantes F1 são considerados estrangeiros temporários para fins fiscais, e por essa razão, estão isentos dos requisitos de saúde do ACA. \n\n" +
            "2. Residentes Fiscais (Após Cinco Anos ou Outras Condições Fiscais) \n" +
            "Após cinco anos de permanência nos EUA ou se o estudante F1 atender a critérios específicos (como o teste de presença substancial do IRS), ele pode ser considerado um residente fiscal. Nesse caso, o estudante será elegível para se inscrever no Obamacare e, dependendo de sua renda, poderá até se qualificar para subsídios do ACA, tornando os planos mais acessíveis. \n\n" +
            "Importante: A classificação de residente fiscal ou não residente fiscal pode ser complicada. Por isso, é altamente recomendado consultar um contador especializado ou usar os recursos do IRS (Internal Revenue Service) para determinar corretamente seu status fiscal. Para mais informações sobre como verificar seu status fiscal, acesse o site oficial do IRS: IRS - Teste de Presença Substancial. \n\n" +
            "*[O que Fazer se Você For Elegível para o Obamacare?]* \n\n" +
            "Se você for considerado residente fiscal nos EUA, você poderá se inscrever no Affordable Care Act (ACA) e acessar os planos de saúde disponíveis no marketplace de saúde através do site HealthCare.gov. Lá, você poderá comparar diferentes planos, levando em conta fatores como cobertura, custos e elegibilidade para subsídios. \n\n" +
            "No entanto, a inscrição no ACA pode ser um processo complexo, especialmente para estudantes internacionais, que podem ter dificuldades para entender os detalhes do processo ou como preencher corretamente os formulários. Para garantir que você esteja aproveitando ao máximo as opções de seguro de saúde disponíveis, é altamente recomendável procurar ajuda de um profissional especializado. \n\n" +
            "*[Royal Eagle Insurance]* \n" +
            "Auxílio Personalizado para Brasileiros nos EUA. \n\n" +
            "Se você está em dúvida sobre como escolher o melhor plano de saúde através do ACA, Edimara Pasinato, da Royal Eagle Insurance, pode ser uma excelente opção. Ela é uma profissional brasileira com vasta experiência em ajudar brasileiros nos EUA a encontrar as melhores opções de seguro de saúde, incluindo os planos do Obamacare. \n\n" +
            "Edimara já ajudou centenas de brasileiros a entender as complexidades do ACA, garantindo que seus clientes escolham os planos de saúde que melhor atendem às suas necessidades. Entre em contato com a Royal Eagle Insurance para uma consultoria personalizada e orientações sobre o processo de inscrição no ACA. \n\n" +
            "*[Links Úteis:]* \n\n" +
            "HealthCare.gov (Marketplace Federal):\n" +
            "https://www.healthcare.gov \n\n" +
            "IRS - Teste de Presença Substancial (Para determinar seu status fiscal): \n" +
            "https://www.irs.gov/individuals/international-taxpayers/substantial-presence-test \n"
    },
    {
        id: 2,
        link: 'Quanto-custa-uma-apolice-de-Life-Insurance-de-500-mil-dolares-por-mes',
        isActive: true,
        image: PostIdImage2,
        date: '22/01/2025',
        title: 'Quanto custa uma apólice de Life Insurance de 500 mil dólares por mês',
        category: 'Life Insurance',
        text: 
            "Nos Estados Unidos, entender o custo de um life insurance policy (seguro de vida) é essencial para tomar decisões financeiras inteligentes. Uma das perguntas mais frequentes é: quanto custa uma apólice de 500 mil dólares por mês? Embora não haja uma única resposta fixa, pois o valor depende de diversos fatores, é possível estimar custos com base em situações comuns do cotidiano americano.\n\n"+

           "*[Fatores que influenciam o custo de uma apólice]*\n\n"+

           "O valor mensal de uma apólice de life insurance varia de acordo com os seguintes fatores principais:\n\n"+

           " 1. Idade:\n\n"+
           "- Quanto mais jovem você é, menor o custo. Por exemplo, uma pessoa de 30 anos pode pagar cerca de $25 a $35 por mês por uma apólice de 500 mil dólares, enquanto uma pessoa de 50 anos pode pagar entre $100 e $150.\n\n"+

           " 2. Saúde geral:\n\n"+
            " - Se você tem uma boa saúde, sem condições crônicas como diabetes ou hipertensão, o custo será menor.\n"+
            " - Exemplo: Uma pessoa que faz exames anuais e pratica atividades físicas regularmente pode obter melhores taxas.\n\n"+

           " 3. Tipo de apólice:\n\n"+
           " - Term life insurance: Seguro temporário que cobre um período fixo, como 10, 20 ou 30 anos. Este é geralmente mais barato.\n"+
           " - Exemplo: Um homem de 35 anos pode pagar $30 por mês por uma apólice de 20 anos.\n"+
           " - Whole life insurance: Seguro vitalício, que inclui um componente de poupança. Este é mais caro, mas oferece benefícios adicionais.\n"+
           " - Exemplo: A mesma pessoa de 35 anos poderia pagar $200 ou mais por mês por um seguro vitalício.\n\n"+

           " 4. Gênero:\n\n"+
           " - Estatisticamente, as mulheres pagam menos do que os homens porque têm uma expectativa de vida mais longa.\n\n"+

           " 5. Estilo de vida:\n\n"+
           " - Fumantes e pessoas com hábitos de risco (como esportes radicais) pagam mais.\n"+
           " - Exemplo: Um fumante pode pagar até o dobro do prêmio mensal em comparação a um não fumante.\n\n"+

           "*[Exemplos práticos de custo]*\n\n"+

            "1. Homem de 30 anos, não fumante, boa saúde:\n\n"+
            "- Term life insurance (20 anos): $25 a $35 por mês.\n"+
            "- Whole life insurance: $200 a $250 por mês.\n\n"+

            "2. Mulher de 40 anos, fumante ocasional:\n\n"+
            "- Term life insurance (20 anos): $60 a $80 por mês.\n"+
            "- Whole life insurance: $300 a $400 por mês.\n\n"+

            "3. Homem de 50 anos, com histórico de hipertensão:\n\n"+
            "- Term life insurance (10 anos): $100 a $150 por mês.\n"+
            "- Whole life insurance: $400 a $600 por mês.\n\n"+

            "*[Por que escolher um seguro de vida?]*\n\n"+

            "O life insurance é uma ferramenta financeira que garante tranquilidade para você e sua família. O valor da apólice pode ser usado para:\n\n"+

            "- Pagar dívidas (como hipoteca ou empréstimos estudantis).\n"+

            "- Custear despesas de educação dos filhos.\n"+

            "- Garantir estabilidade financeira após a perda do principal provedor.\n\n"+

            "*[Fontes confiáveis para estimativas de custos]*\n\n"+

            "*[HealthCare.gov]* (https://www.healthcare.gov)\n"+

            "*[NAIC]* (National Association of Insurance Commissioners) (https://content.naic.org)\n"+

            "*[IRS]* (Internal Revenue Service) (https://www.irs.gov)\n\n"+

            "*[Conclusão]*\n\n"+

            "O custo de uma apólice de 500 mil dólares depende de fatores como idade, saúde, estilo de vida e tipo de apólice. Ao entender essas variáveis, você pode escolher o seguro que melhor atende às suas necessidades e ao seu orçamento. Pesquise, compare opções e, se possível, consulte um agente de seguros para orientação personalizada.\n\n"+

            "Um seguro de vida pode garantir segurança financeira para sua família, sem pesar no bolso. Quer entender como uma apólice de $500K pode fazer sentido para você? Estou aqui para te ajudar a encontrar a melhor opção, com toda tranquilidade e clareza.\n\n"+

            "📞 *[Ligue:]* (346) 745-0578 | (346) 351-9858\n"
        
    },
    {
        id: 3,
        link: 'Auto-Insurance-em-2025-Por-Que-Brasileiros-Nos-EUA-Pagam-Mais-Por-Nao-Ler-a-Apolice',
        isActive: true,
        image: PostIdImage3,
        date: '13/03/2025',
        title: 'Por que brasileiros nos EUA pagam mais por não ler a apólice?',
        category: 'Auto Insurance',
        text: 
            `Muitos aumentos no seguro de carro em 2025 são causados por documentos ignorados e falta de atenção a cláusulas importantes. Entenda os motivos e como agir.

            Muitos segurados não leem suas apólices detalhadamente, o que pode levar a mal-entendidos sobre a cobertura e, consequentemente, a aumentos nos prêmios. Isso é especialmente relevante para brasileiros nos EUA, que podem enfrentar barreiras linguísticas e culturais.
            
            "As regras do seguro mudam conforme leis estaduais, tipo de veículo e até o ZIPCODE. Quem não lê, paga mais, é matemática simples", afirma *[Edimara Pasinato, especialista em seguros (Texas)]*.

            Seguradoras atualizam apólices silenciosamente (e você paga por isso). Muitas reclamações de aumento nos prêmios estão relacionadas a cláusulas não compreendidas ou ignoradas, como exclusões de cobertura ou requisitos específicos.
            
            Caso real: Uma família de Orlando viu o seguro subir 35% após se mudar para uma área de risco de furacões, detalhe que estava no parágrafo 8.2 do e-mail ignorado.

            *[Segurados podem perder oportunidades de descontos, os que não acompanham suas apólices podem perder benefícios como:]*
            
            - Desconto por direção segura (comprovada por telemetria).
            
            - Redução de franquia para quem faz revisões anuais.

            *[Como evitar aumentos em 2025 (sem depender de sorte): Revisar sua apólice regularmente pode ajudar a evitar surpresas. Basta:]*
            
            - Configurar filtros de e-mail para palavras como "endorsement" ou "amendment".

            - Usar leitores de PDF com tradução automática (ex.: Adobe Acrobat).
            
            - Usar ferramentas digitais, como apps de seguradoras, pode ajudar a monitorar e otimizar sua apólice.- Estar atualizado com as leis estaduais é crucial, pois seguros são ajustados conforme novas regulamentações.

            *[O que fazer hoje:]*  

            *[Passo 1:]* 
            Abra o último e-mail da sua seguradora. Procure por "changes to your policy". 
            
            *[Passo 2:]*
            Use o glossário gratuito da NAIC (https://www.naic.org/consumer_glossary.htm) para termos técnicos.
            
            *[Passo 3:]*
            Se precisar de ajuda para decifrar cláusulas, fale com a Royal Eagle Insurance.
            "Analisamos apólices e encontramos erros comuns. Quer uma revisão gratuita?  

            *[Fale conosco:]*

            Edimara Pasinato
            (346) 351-9858

            Fernanda Leite
            (346) 745-0578

            Mônica Hennessey
            (346) 351-9372

            Siga @royaleagleins

            *[Referências:]*

            NAIC - Como ler uma apólice (https://content.naic.org/article/consumer_auto_insurance_guide)

            Consumer Reports - Erros comuns em seguros (https://www.consumerreports.org/car-insurance/car-insurance-errors)
            
            Texas Department of Insurance - Novas regras para carros elétricos (https://www.tdi.texas.gov)
            `
        
    },
]

export default function getPost(postLink){

    return posts.find(post => postLink == post.link)

}