import IdPostImg1 from './blogImage/postIdThumbnail1.jpg'
import IdPostImg2 from './blogImage/postIdThumbnail2.jpg'
import IdPostImg3 from './blogImage/postIdThumbnail3.jpg'

export const blogList = [
    {
        id: 1,
        link: 'Obamacare-para-estudantes-com-visto-F1',
        isActive: true,
        isSeeMore: true,
        miniImage: IdPostImg1,
        resume: 'Estudar nos Estados Unidos pode ser uma experiência incrível, mas um dos maiores desafios para estudantes internacionais, especialmente para os brasileiros com visto F1, é o alto custo dos cuidados médicos.',
        date: '09/01/2025',
        category: 'Health Insurance',
        title: 'Obamacare para estudantes com visto F1'
    },
    {
        id: 2,
        link: 'Quanto-custa-uma-apolice-de-Life-Insurance-de-500-mil-dolares-por-mes',
        isActive: true,
        isSeeMore: true,
        miniImage: IdPostImg2,
        resume: 'Nos Estados Unidos, entender o custo de um life insurance policy (seguro de vida) é essencial para tomar decisões financeiras inteligentes.',
        date: '22/01/2025',
        category: 'Life Insurance',
        title: 'Quanto custa uma apólice de Life Insurance de 500 mil dólares por mês'
    },
    {
        id: 3,
        link: 'Auto-Insurance-em-2025-Por-Que-Brasileiros-Nos-EUA-Pagam-Mais-Por-Nao-Ler-a-Apolice',
        isActive: true,
        isSeeMore: true,
        miniImage: IdPostImg3,
        resume: 'Muitos segurados não leem suas apólices detalhadamente, o que pode levar a mal-entendidos sobre a cobertura e, consequentemente, a aumentos nos prêmios.',
        date: '13/03/2025',
        category: 'Auto Insurance',
        title: 'Por que brasileiros nos EUA pagam mais por não ler a apólice?'
    },
]